<template>
  <div class="info">
    <top-bar :title="'社区党建'" :left="true"></top-bar>
    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">新增党员</span>
        </template>
      </van-cell>
      <van-field
        clearable
        clear-trigger="always"
        v-model="detailData.idNumber"
        name="用户证件号"
        label="用户证件号"
        placeholder="请输入"
        @change="getUserByIdNumber()"
        :rules="[{ required: true, message: '请填写用户证件号' }]"
      />
      <van-field
        :value-class="{'value-common':!detailData.branchName}"
        is-link
        clickable
        name="所属支部"
        :value="detailData.branchName"
        label="所属支部"
        placeholder="点击选择所属支部"
        @click="showPicker = !isEdit"
        :rules="[{ required: true, message: '请选择所属支部' }]"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="partyRelationList"
          value-key="name"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        :value-class="{'value-common':!detailData.postName}"
        is-link
        clickable
        name="所属职务"
        :value="detailData.postName"
        label="所属职务"
        placeholder=""
        @click="showPicker1 = !isEdit"
        :rules="[{ required: true, message: '请选择所属职务'}]"
      />
      <van-popup v-model="showPicker1"  position="bottom">
        <van-picker
          show-toolbar
          :columns="postList"
          value-key="label"
          @confirm="onConfirmPost"
          @cancel="showPicker1 = false"
        />
      </van-popup>
      <van-field
          :value-class="{'value-common':!detailData.joinBranchDate}"
          is-link
          clickable
          name="入支日期"
          :value="detailData.joinBranchDate"
          label="入支日期"
          @click="showDatePicker2 = !isEdit"
      />
      <van-popup v-model="showDatePicker2" position="bottom">
        <van-datetime-picker
            type="date"
            :min-date="minDate"
            @confirm="onConfirmDate2"
            @cancel="showDatePicker2 = false"
        />
      </van-popup>
      <van-field
        :value-class="{'value-common':!detailData.joinDate}"
        is-link
        clickable
        name="入党日期"
        :value="detailData.joinDate"
        label="入党日期"
        v-show="detailData.partyType == '1'"
        @click="showDatePicker = !isEdit"
        :rules="[{ required: true, message: '请填写入党日期' }]"
      />
      <van-popup v-model="showDatePicker" position="bottom">
        <van-datetime-picker
          type="date"
          :min-date="minDate"
          @confirm="onConfirmDate"
          @cancel="showDatePicker = false"
        />
      </van-popup>
      <van-field
        :value-class="{'value-common':!detailData.correctionDate}"
        is-link
        clickable
        name="转正日期"
        :value="detailData.correctionDate"
        label="转正日期"
        v-show="detailData.partyType == '2'"
        @click="showDatePicker1  = true"
        :rules="[{ required: true, message: '请填写转正日期' }]"
      />
      <van-popup v-model="showDatePicker1" position="bottom">
        <van-datetime-picker
          type="date"
          :min-date="minDate"
          @confirm="onConfirmDate1"
          @cancel="showDatePicker1 = false"
        />
      </van-popup>
      <van-field name="流动党员" label="流动党员" input-align="right">
        <template #input>
          <van-switch v-model="detailData.isFlow"  size="20" />
        </template>
      </van-field>
      <van-field
        :value-class="{'value-common':!detailData.partyType}"
        is-link
        clickable
        name="党员类型"
        :value="detailData.partyType=='2'?'预备党员':'正式党员'"
        label="党员类型"
        placeholder="点击选择党员类型"
        @click="showPartyType = !isEdit"
      />
      <van-popup v-model="showPartyType" position="bottom">
        <van-picker
          show-toolbar
          :columns="partyType"
          value-key="label"
          @confirm="onConfirmPartyType"
          @cancel="showPartyType = false"
        />
      </van-popup>
    </van-cell-group>

    <van-row class="btns">
      <van-col :span="24">
        <van-button type="info" size="large" round @click="onSubmit">提交</van-button>
      </van-col>
    </van-row>

  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {getImageStream} from '@/utils/index'
import { mapState } from 'vuex'
import {formatterDate}  from '@/utils/utils'
export default {
  name: "party-info-add",
  data () {
    return {
      value: '',
      detailData: {
        partyType:1,
        joinBranchDate: ''
      },
      fileList:[],
      isEdit:true,
      partyRelationList:[],
      showPicker:false,
      postList:[],
      showPicker1:false,
      showDatePicker:false,
      showPartyType:false,
      showDatePicker1:false,
      showDatePicker2:false,
      minDate: new Date(1930, 0, 1),
      partyType:[{
        label: '正式党员',
        value: '1',
      },
        {
          label: '预备党员',
          value: '2',
        }]

    }
  },
  computed:{...mapState(['partyId','branchId'])},
  components :{
    topBar
  },
  methods: {
    onSubmit(values){
      this.$delete(this.detailData,'orgIds')
      this.detailData.branchId = this.branchId;
      this.detailData.community = this.$orgId;
      if (this.detailData.idNumber == undefined) {return this.$toast.fail('失败:用户证件号不能为空');}
      if (this.detailData.userId == undefined) {return this.$toast.fail('失败:请输入正确的社区用户证件号');}
      if (this.detailData.branchName == undefined) {return this.$toast.fail('失败:请选择所属支部');}
      if (this.detailData.postName == undefined) {return this.$toast.fail('失败:请选择所属职务');}
      if (this.detailData.partyType == '1') {
        if (this.detailData.joinDate == undefined) {return this.$toast.fail('失败:请选择入党日期');}
      } else {
        if (this.detailData.correctionDate == undefined) {return this.$toast.fail('失败:请选择转正日期');}
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/party/save'),
        method: 'post',
        data: this.$http.adornData(this.detailData)
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success({
            message: '新增成功',
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.isEdit = true;
                this.$toast.clear()
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    onConfirm(value) {
      this.detailData.branchName = value.name;
      this.detailData.branchId = value.id;
      this.showPicker = false;
    },
    onConfirmPost(value) {
      this.detailData.postName = value.label;
      this.detailData.post = value.value;
      this.showPicker1 = false;
    },
    onConfirmDate(time) {
      this.detailData.joinDate = formatterDate(time);
      this.showDatePicker = false;
    },
    onConfirmDate1(time) {
      this.detailData.correctionDate = formatterDate(time);
      this.showDatePicker1 = false;
    },
    onConfirmDate2(time) {
      this.detailData.joinBranchDate = formatterDate(time);
      this.showDatePicker2 = false;
    },
    onConfirmPartyType(value) {
      this.detailData.partyType = value.value;
      this.showPartyType = false;
    },
    formatter(type,val){
    },
    getpartyRelation(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/partyRelation/list'),
        method: 'post',
        params: this.$http.adornParams({
          community: this.$orgId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          let list = data.page.list
          this.partyRelationList =list;
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    getpartyPost(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'partyPost',
          orgId: this.$orgId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          let list = data.dicts
          this.postList =list;
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    } ,
    getUserByIdNumber(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/userByIdNumber'),
        method: 'get',
        params: this.$http.adornParams({
          idNumber: this.detailData.idNumber
        })
      }).then(({data})=> {
        if (data.code == 0) {
          if (data.userInfoList.length > 0) {
            this.detailData.userId = data.userInfoList[0].id;
          }
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
  },
  created () {
    document.querySelector('html').style.backgroundColor = '#fff'
    this.isEdit = false;
    //调用方法 获取党支部列表/党员职务列表
    this.getpartyRelation();
    this.getpartyPost();

  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  },

}
</script>

